@import "../../../../App.scss";

.addpatient-tab {
  .addpatient-heading {
    font-family: $Montserrat-Medium;
    font-size: $font-size16;
    line-height: 20px;
    color: #202020;
  }
  .addpatient-breadcrumb {
    font-family: $Montserrat-Medium;
    padding-top: 1rem;
    font-size: $font-size14;
    line-height: 17px;

    img {
      padding: 0 22px;
    }
    .current-tab {
      color: #4fa6d1;
    }
  }

  @media only screen and (max-width: 489px) {
    .addpatient-breadcrumb {
      text-align: left;
      font-size: 12px;
    }
  }

  .wrapper {
    width: 100%;
    margin-bottom: 100px;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -11.5px;
      margin-right: -11.5px;

      .col-lg-8 {
        flex-basis: 68%;
        order: 1;
        background-color: #ffffff;
        margin-bottom: 23px;
        margin-left: 11.5px;
        margin-right: 11.5px;
        flex-shrink: 0;
        flex-grow: 0;
        height: auto;
        border: 1px solid #e4e3e4;
        border-radius: 6px;
        padding: 0px;
      }

      .col-lg-4 {
        flex-basis: 29%;
        order: 2;
        background-color: #ffffff;
        margin-bottom: 23px;
        margin-left: 11.5px;
        margin-right: 11.5px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #e4e3e4;
        border-radius: 6px;
        padding: 0px;
        height: 100%;
      }
    }

    @media (max-width: 1459px) {
      .col-lg-8,
      .col-lg-4 {
        flex-basis: 100% !important;
        max-width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .left-div,
      .right-div {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .row.second-row .col-lg-8 {
      /* unique styles for second row col-lg-8 here */
      width: 691px;
      height: auto;
      background: #ffffff;
      border: 1px solid #e4e3e4;
      border-radius: 6px;
    }

    @media (max-width: 992px) {
      .row.second-row .col-lg-8 {
        flex-basis: 100% !important;
        order: unset !important;
        margin-bottom: 23px !important;
        flex-shrink: 0 !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
}
