.reviewer{
    &-pic{
        width: 40px;
        height: 40px;
    
        img{
            border-radius: 50px;
            object-fit: cover;
        }
    }

    &-name{
color: #202124;
font-size: 16px;
    }
}