@import "../../../../App.scss";

.doctors-list-wrapper {
  .header {
    height: 54px;
    font-family: $Montserrat-SemiBold;
  }
  .title {
    color: #000000;
    font-size: $font-size12;
    padding: 15px 0px 20px 10px;
  }

  .doctors-list {
    height: 560px;
    overflow-y: auto;
    font-family: $Montserrat-Medium;
    padding: 20px;

    .doctor-container {
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 22px;
      .doctor-image-container{
        width: 76px;
        height: 76px;
        border-radius: 12px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 12px;
        }
      }

      .doctor-detail {
        font-size: $font-size12;
        .name {
          color: #535353;
        }
        .specialization {
          color: #8c8c8c;
        }
        .experience {
          color: #8c8c8c;
        }
      }
    }
  }

  button {
    font-family: $Montserrat-Medium;
    margin: 20px;
    font-size: $font-size12;
    background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 156px;
    height: 48px;
    line-height: 15px;
    &:hover {
      background: #125a77;
    }
    &:focus {
      outline: none;
    }
  }
}
