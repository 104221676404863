@import "../../../../App.scss";

.bloodtest-tab {
  .bloodtest-heading {
    font-family: $Montserrat-Medium;
    font-size: $font-size16;
    line-height: 20px;
    color: #202020;
  }
  .bloodtest-breadcrumb {
    font-family: $Montserrat-Medium;
    padding-top: 1rem;
    font-size: $font-size14;
    line-height: 17px;

    img {
      padding: 0 22px;
    }
    .current-tab {
      color: #4fa6d1;
    }
  }

  .add-test {
    width: 156px;
    height: 48px;
    background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
    border-radius: 6px;
    color: #ffffff;
    font-family: $Montserrat-Medium;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    border: none;

    &:focus,
    &:active {
      outline: none; // Add this to remove the border on click
      /* any other existing styles for :focus or :active */
    }
    &:hover {
      background: #125a77;
    }
  }

  @media only screen and (max-width: 489px) {
    .bloodtest-breadcrumb {
      text-align: left;
      font-size: 12px;
    }
  }

 
}
