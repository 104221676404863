.upload-profile-img{
    background-color: white;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E3E4;
    border-radius: 50px;
    img{
        width: 35%;
        height: 35%;
    }
}

.upload-profile-img-final{
    background-color: white;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E3E4;
    border-radius: 50px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }
}