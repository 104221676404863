@import "../../App.scss";

.sign-page {
  height: 100vh;
  width: 100%;
  background-image: url("../images/sign/sign_bg_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  &-background {
    background: linear-gradient(
      323.79deg,
      rgba(18, 90, 119, 0.5) 35.68%,
      rgba(57, 125, 153, 0.5) 92.17%
    );
    height: 100vh;
    width: 100%;

    &-row {
      width: 100%;
    }

    &-maweidi-logo img {
      height: 6.8%;
    }
  }
}

.maweidi-logo img {
  height: 70px;
}

.siginup-inner {
  background: #ffffff;
  border: 1px solid #e4e3e4;
  border-radius: 10px;
}

.signup-text1 {
  font-family: $Poppins-Medium;
  font-style: normal;
  font-size: $font-size22;
  line-height: 33px;
  color: #193f52;
}

.signup-text2 {
  font-family: $Poppins-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #193f52;
}

.forgot-text1 {
  font-family: $Poppins-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #193f52;
}

.forgot-text2 {
  font-family: $Poppins-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
}

.signup-input-label p {
  font-family: $Poppins-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
}

.signup-input-label input[type="text"],
.signup-input-label input[type="password"] {
  width: 100%;
  height: 36px;
  padding-left: 1.3%;
  border: 1px solid #e4e3e4;
  border-radius: 5px;
  height: 36px;

  &:focus {
    outline: none;
  }
}

.sdfgsdg::placeholder {
  font-size: 5px;
  padding-top: 3%;
}

.signup-input-label-input input {
  padding-left: 2.3%;
}

.PleaseAcceptCheckbox {
  font-family: $Poppins-Regular !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  margin-top: 0.3% !important;
  color: #8c8c8c !important;
  padding-top: 0%;
}

.PleaseAcceptCheckbox span {
  color: #193f52;
}

.PleaseAcceptCheckboxSignin,
.PleaseAcceptCheckboxSignin:hover {
  font-family: $Poppins-Regular !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  margin-top: 0.3% !important;
  color: #8c8c8c !important;
  text-decoration: none;
}

.resgister-button button {
  width: 100%;
  height: 54px;
  background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
  border-radius: 6px;
  border: none;
  font-family: $Poppins-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}

.resgister-button button:focus {
  outline: none;
}

.signup-lower-text {
  p {
    font-family: $Poppins-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    color: #8c8c8c;
  }

  span {
    a {
      color: #193f52;
      font-family: $Poppins-Bold;
      font-size: 11px;
    }
  }
}

.sign-text, 
.sign-text:hover{
font-family: $Poppins-Regular;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  color: #193F52;
  font-weight: 600;
  text-decoration: none;
}

.maweidi-link,
.maweidi-link:hover {
  text-decoration: none;
}

.Checkboxlabel:focus,
.CheckboxInput:focus {
  outline: none;
}

.signup-staric-color {
  color: #ec0303;
}

@media (min-height: 600px) and (max-height: 650px) {
  .signup-input-label-accept {
    margin-top: 3% !important;
  }

  .signup-input-label-input-top {
    margin-top: -9px !important;
  }
}
.error_message{
  font-size: 0.78vw;
  color: red;
  @media (max-width:480px) {
    font-size: 2.3vw;
  }
}