@import "../../App.scss";

.dot {
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.dashboardDateRange{
  .ant-picker-clear{
    &::after {
      background-image: url('../../assets/images/doctor/BrowseDocumentDeleteIcon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      width: 12px; 
      height: 12px;
      position: relative;
      content:"";
    }
  }

  .anticon-swap-right svg{
    background-color: #50cd89;
    display: none;
  }

  .anticon-calendar svg{
    background-color: #50cd89;
    display: none;
  }
}

.dashboard-card {
  a:hover {
    text-decoration: none;
  }
  width: 20%;
  @media (min-width: 0px) and (max-width: 990px) {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.SidebarMenuPadding{
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.sidebarMenuBorderRadius{
  border-radius: 6px;
}

.MenuItemsTextMargin{
  padding-left: 71px;
  height: 40px;
}

.selected-menu-bg{
  background-color: #E5F2F84D;
}

.selected-menu-submenu-bg{
  background-color: #F7F7F71A;
}

.selected-menu-icon-bg{
  background-color: #ffffff !important;
}

// .hover-effect{
//   a:hover{
//     opacity: 0.6;
//   }
// }

.dashboard-main-hover {
  transition: 0 all;
}

.dashboard-main-hover:hover {
  background-color: #1118271a;
  border-radius: 5px;
}

.dashboard-main-hover:hover {
  background-color: #1118271a;
  border-radius: 5px;
}

.dashboard-main-hover:hover {
  background-color: #1118271a;
  border-radius: 5px;
}

.dashboard-logo img {
  width: 130px;
  height: 54px;
}

.menu-button {
  background: rgba(79, 166, 209, 0.15);
  box-shadow: 0px 4px 4px rgba(79, 166, 209, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.menu-button i {
  font-size: 20px;
  color: #193f52;
}

.site-footer {
  height: 72px;
  background: #ffffff;
  border-top: 1px solid #e4e3e4;

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8c8c8c;
  }
}

.header-search {
  height: 42px;
  border: 1px solid #e9ecef;
  border-radius: 20px;
}

.header-search input {
  width: 100%;
  border: none;
  margin-bottom: 0.4%;
}

.header-search input:focus {
  outline: none;
}

.header-search img {
  width: 17px;
  height: 17px;
}

.header-search-mobile {
  height: 42px;
  border: 1px solid #e9ecef;
  border-radius: 20px;
}

.header-search-mobile input {
  width: 40vw;
  border: none;
  margin-bottom: 0.4%;
}

.header-search-mobile input:focus {
  outline: none;
}

.header-search-mobile img {
  width: 17px;
  height: 17px;
}

.profile-menu-button-right-img {
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(79, 166, 209, 0.1);
}

.menu-button-right-img {
  width: 47.2px;
  height: 47.2px;
  border-radius: 50%;
}

.dashboard-left-icon {
  background: rgba(79, 166, 209, 0.15);
  border-radius: 5px;
  width: 45px;
  height: 45px;
  @include flex-justify-align;
}

.dashboard-left-icon img {
  width: 30px;
  height: 30px;
}

.dashboard-left-icon-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4fa6d1;
}

.dashboard-com-top-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
}

.dashboard-left-icon-top-text1 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #193f52;
}

.dashboard-left-icon-top-text2 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8c8c8c;
}

.dashboard-right-side-top-card {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
}

.bar-chart {
  position: relative;

  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;

  &-padding {
    padding-left: 5%;
    padding-right: 5%;
  }

  &-text1 {
    font-family: $Montserrat-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #202020;
  }

  &-text2 {
    position: absolute;
    top: 48%;
    left: 0%;
    font-family: $Montserrat-Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #8c8c8c;

    transform: rotate(-90deg);
  }
}

.Radial-bar-border {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
  height: 100%;
}

.appoinment-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #202020;
}

.appoinment-count-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8c8c8c;
}

.appoinment {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;

  &-detail {
    &-img {
      width: 50px;
      height: 50px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      border-radius: 50%;
    }

    &-text {
      &-1 {
        font-family: $Montserrat-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #202020;
      }

      &-2 {
        font-family: $Montserrat-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #b2b2b2;
      }

      &-3 {
        font-family: $Montserrat-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #b2b2b2;
      }
    }
  }
}

.doctor {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;

  &-detail {
    &-img {
      width: 60px;
      height: 60px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      border-radius: 50%;
    }

    &-text {
      &-1 {
        font-family: $Montserrat-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #202020;
      }

      &-2 {
        font-family: $Montserrat-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #b2b2b2;
      }
    }
  }
}

.doctor-btn {
  width: 80px;
  height: 38px;
  border: 1px solid #e4e3e4;
  border-radius: 6px;
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #193f52;
  background-color: transparent;
}

.doctor-btn:focus {
  outline: none;
}

.old-dropdown-text1 {
  transition: 1s all;
  background-color: white;
}

.old-dropdown-text1:hover {
  background-color: rgba(17, 24, 39, 0.1019607843);
}

.patient-btn-filter {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #193f52;
}

/* Mobile Menu css  */

.notheight {
  width: 0%;
  height: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: height 0.3s;
  transition: width 0.3s;
}

.notheight1 {
  max-height: 0px;
  overflow-y: hidden;
}

.mobile-screen {
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  max-height: 100vh;
  z-index: 10000;
}

.InnerClassMobileScreen {
  width: 65%;
  background-color: white;
  height: 100vh;
}

.MobileMenu1Icon {
  font-size: 25px;
}

.headerMobileSideBar {
  display: flex;
  flex-direction: column;
}

.headerMobileSideBar a {
  margin-top: 10px;
  margin-left: 10%;
  text-decoration: none;
  color: white;
  width: 80%;
  height: 40px;
  border-radius: 30px;
  text-align: center;
  font-size: 6vw;
  display: flex;
  align-items: center;
}

//new mobile

.mobile-menu {
  &-detail {
    &-img {
      width: 6.5vw;
    }

    &-text1 {
      font-family: $Montserrat-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 2.6vw;
      line-height: 17px;
      color: #4fa6d1;
    }

    &-text2 {
      font-family: $Montserrat-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 2.6vw;
      line-height: 17px;
      color: #535353;
    }
  }
}

/* Mobile Menu css ^ */

.left-drop-down img {
  width: 6px;
}

.left-drop-down span {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #535353;
}

.appoinment-correction {
  &-correct {
    width: 22px;
    height: 22px;
    background-color: red;
    @include flex-justify-align;
    background: #50cd89;
    box-shadow: 0px 2px 2px rgba(80, 205, 137, 0.16);
    border-radius: 50%;

    &:hover {
      background: rgba(80, 205, 137, 0.2);
      border: 0.3px solid #50cd89;
    }

    &-i {
      padding-top: 12%;
      font-size: 11px;
      color: white;

      &:hover {
        color: #50cd89;
      }
    }
  }

  &-incorrect {
    width: 22px;
    height: 22px;
    background-color: red;
    @include flex-justify-align;
    background: #ec826e;
    box-shadow: 0px 2px 2px rgba(80, 205, 137, 0.16);
    border-radius: 50%;

    &:hover {
      background: rgba(236, 130, 110, 0.3);
      border: 0.3px solid #ec826e;
    }

    &-i {
      padding-top: 8%;
      font-size: 11px;
      color: white;

      &:hover {
        color: #ec826e;
      }
    }
  }
}

.profile-drop-down-img {
  width: 41.2px;
  height: 41.2px;
  border-radius: 50%;
}

.profile-drop-down-text1 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #535353;
}

.profile-drop-down-text2 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #8c8c8c;
}

.profile-drop-down-clock-img {
  width: 24px;
}

.profile-drop-down-clock-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4fa6d1;
}

.profile-drop-down-logout-text {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #535353;
}

.profile-drop-down-body {
  width: 199px;
  margin-right: 10px;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
}

.notification-drop-down-body {
  width: 389px;
  margin-right: 10px;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
}

/* CSS code */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.old-dropdown-text1,
.old-dropdown-text2 {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #535353;
}

.old-dropdown-text1:hover,
.old-dropdown-text2:hover {
  background: rgba(79, 166, 209, 0.1);
}

.noti-drop-down-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.noti-drop-down-text1 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #535353;
}

.noti-drop-down-text2 {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  color: #8c8c8c;
}

.notification-drop-down-body-mobile {
  width: 366px;
  margin-right: 265.5px;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
}

.old-drop-down {
  width: 58px;
}

.animation-dashboard {
  transition: 0s all;
}

.third-sub-menu span {
  font-family: $Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-top: 30px;
  color: #535353;
}

.third-sub-menu img {
  width: 5px;
  padding-bottom: 4px;
}

@media (min-width: 500px) and (max-width: 990px) {
  .dashboard-left-icon {
    background: rgba(79, 166, 209, 0.15);
    border-radius: 5px;
    width: 45px;
    height: 35px;
    @include flex-justify-align;
  }

  .dashboard-left-icon-text {
    font-size: 13px;
    padding-bottom: 11px;
    margin-left: -20px;
  }

  .dashboard-left-icon img {
    width: 23px;
    height: 23px;
  }

  .left-drop-down span {
    font-family: $Montserrat-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    color: #535353;
  }

  .headerMobileSideBar a {
    text-decoration: none;
    color: white;
    width: 80%;
    height: 40px;
    border-radius: 30px;
    text-align: center;
    font-size: 6vw;
    display: flex;
    align-items: center;
  }

  .sub-menu1-top-padding {
    margin-top: 0px !important;
    height: 25px;
  }

  .sub-menu1-top-padding span {
    padding-bottom: 10px;
    margin-left: -25px !important;
  }

  .sub-menu1-top-padding img {
    width: 5px;
    margin-left: 15px;
  }

  .size-small-screen {
    width: 9px;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
  .dashboard-left-icon {
    background: rgba(79, 166, 209, 0.15);
    border-radius: 5px;
    width: 45px;
    height: 35px;
    @include flex-justify-align;
  }

  .dashboard-left-icon-text {
    font-size: 13px;
    padding-bottom: 11px;
  }

  .dashboard-left-icon img {
    width: 23px;
    height: 23px;
  }

  .left-drop-down span {
    font-family: $Montserrat-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    color: #535353;
  }

  .headerMobileSideBar a {
    text-decoration: none;
    color: white;
    width: 80%;
    height: 40px;
    border-radius: 30px;
    text-align: center;
    font-size: 6vw;
    display: flex;
    align-items: center;
  }

  .sub-menu1-top-padding {
    margin-top: 0px !important;
    height: 25px;
  }

  .sub-menu1-top-padding span {
    padding-bottom: 10px;
    margin-left: -12px !important;
  }

  .sub-menu1-top-padding img {
    width: 5px;
  }

  .size-small-screen {
    width: 9px;
  }
}

@media (min-width: 1340px) and (max-width: 1550px) {
  .dashboardCom-scroller-appoinment {
    padding-right: 1.2%;
  }

  .dashboardCom-scroller-doctor {
    padding-left: 1.2%;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .dashboard-left-icon {
    background: rgba(79, 166, 209, 0.15);
    border-radius: 5px;
    width: 37px;
    height: 37px;
    @include flex-justify-align;
  }

  .dashboard-left-icon img {
    width: 27px;
    height: 27px;
  }

  .dashboard-left-icon-top-text1 {
    font-family: Montserrat Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #193f52;
  }

  .dashboard-left-icon-top-text2 {
    font-family: Montserrat Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #8c8c8c;
  }

  .bar-chart-text1 {
    font-size: 11px;
  }
}

@media (min-width: 1055px) and (max-width: 1390px) {
  .dashboard-left-icon-text {
    font-size: 0.8vw;
    font-weight: 500;
  }
  .left-drop-down span {
    font-size: 0.8vw;
  }
  .left-drop-down img {
    padding-top: 4%;
  }
}

@media (min-width: 992px) and (max-width: 1055px) {
  .dashboard-left-icon-text {
    font-size: 1vw;
    font-weight: 500;
  }
  .left-drop-down span {
    font-size: 0.8vw;
  }
  .left-drop-down img {
    padding-top: 4%;
  }
}

/* In menu form */

.top_bar,
.bottom_bar {
  stroke-dasharray: 240px 910px;
}

.middle_bar {
  stroke-dasharray: 240px 240px;
}

/* In cross form */

.open .top_bar,
.open .bottom_bar {
  stroke-dashoffset: -650px;
}

.open .middle_bar {
  stroke-dasharray: 0px 220px;
  stroke-dashoffset: -120px;
}
