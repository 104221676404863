
.roomslistBody{

    .listHeading{
        font-size: 14px;
        font-weight: 600;
        font-family: Montserrat;
        color: #202020;
    }

    .addRoomButton{
        background: linear-gradient(323.79deg, #125A77 35.68%, #397D99 92.17%);
        color: white;
        border: none;
        outline: none;
        border-radius: 6px;
        padding: 12px 30px;
        font-size: 12px;
        font-weight: 500;
        font-family: Montserrat;
    }

}

// Status Modal Styles

.pendingStatus{
    background-color: #f7eed0;
    border: 1px solid #FFC107;
    color: #ffa807;
}

.acceptedStatus{
    background: #50CD891A;
    border: 1px solid #50CD89;
    color: #50CD89;
}

.declinedStatus{
    background-color: #EC826E4D;
    border: 1px solid #EC826E;
    color: #EC826E;
}

.completedStatus{
    color: rgb(103, 188, 216);
    background-color: rgb(184, 226, 239);
    border: 1px solid rgb(103, 188, 216);
}

.deleteButton{
    background: #ec826e;
    color: #ffffff;
    border: none;
}

.statusModal{

    .statusModalBody{

        text-align: center;
    
        p {
            font-family: "Poppins Regular";
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #535353;
            margin-bottom: 60px;
        }
    
        button{
            width: 150px;
            height: 45px;
            border-radius: 5px;
            margin-right: 5px;
            margin-left: 5px;
            font-family: "Montserrat Medium";
            font-weight: 500;
            font-size: 15px;
        }
    
    }

}

// Hospital Room Form

.inputSpacing{
    padding-left: 0;
    padding-right: 0;
}

.facilityButton{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    // background-color: #125A77;
    // color: white;
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins Regular";
    cursor: pointer;
}

.customButton{
    background: linear-gradient(323.79deg, #125A77 35.68%, #397D99 92.17%);
    color: white;
    border: none;
    outline: none;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat;
    display: flex;
    align-items: center;
    justify-content: center;
}