@import "../../../../App.scss";

.add-new-patient-form-wrapper {
  padding: 33px 32px 70px 32px;
  .add-profile {
    .image-uploader {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        font-family: $Montserrat-Medium;
        gap: 20px;
        color: #535353;
        .image-preview {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid #e4e3e4;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        font-family: $Montserrat-SemiBold;
        color: #193f52;
        line-height: 17px;
        font-size: 14px;
        display: none;
      }
    }
  }

  .add-new-patient-form {
    margin-top: 37px;
    .form-group {
      margin-bottom: 30px;
      font-family: $Poppins-Regular;
      font-size: $font-size14;
      color: #535353;

      label {
        display: block;
        margin-bottom: 10px;
      }

      input[type="text"],
      [type="email"] {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #e4e3e4;
        border-radius: 5px;
        height: 36.6px;
        font-size: $font-size14;

        &:focus {
          outline: 0;
        }
      }

      .ant-picker {
        border-radius: 5px;
        border-color: #e4e3e4;
        box-shadow: none;

        .ant-picker-focused {
          border: none;
          box-shadow: none;
        }
      }

      @media (min-width: 992px) {
        .form-row {
          display: flex;
          flex-wrap: wrap;
          margin-right: -5px;
          margin-left: -5px;

          .form-group {
            flex: 1;
            margin-right: 5px;
            margin-left: 5px;
          }
        }

        .form-group {
          width: 100%;
        }

        .form-group:not(:last-child) {
          margin-right: 15px;
        }

        .form-group:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    button {
      font-family: $Montserrat-Medium;
      font-size: $font-size12;
      background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
      border-radius: 5px;
      color: white;
      padding: 10px 20px;
      margin-top: 30px;
      border: none;
      cursor: pointer;
      width: 156px;
      height: 48px;
      line-height: 15px;
      &:hover {
        background: #125a77;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
