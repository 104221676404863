.image-preview-modal {
    background: rgba(0, 0, 0, 0.6);

    /* Set transparent background */
    button {
        display: none;
    }

    .ant-modal{
        width: auto !important;
    }
}

.image-preview-modal .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
    padding: 0;
    border-radius: 0;
    /* Set transparent background for modal content */
}

.image-preview-modal .ant-modal-body {
    padding: 0 !important;
    /* Remove padding from modal body */
}
.image_container{

    height: 400px;
    img{
        height: 100%;
    }
}