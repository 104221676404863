.banner-selection .ant-select-selector {
    border: none !important;
    height: 36px !important;
}

.banner-selection .ant-select-selection-item {
    color: #193F52 !important;
    font-family: "Montserrat SemiBold" !important;
    font-style: normal !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.banner-time-border {
    border: 1px solid #E4E3E4;
    border-radius: 5px;
}

.submit-save-banner{
    width: 100%;
    height: 37px;
}