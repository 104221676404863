body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  padding-bottom: 16px;
  position: relative;
  bottom: 16px;
}
.space-x-2 > div:first-of-type {
  padding: 0 !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  padding-right: calc(5px * var(--tw-space-x-reverse)) !important;
  padding-left: calc(5px * calc(1 - var(--tw-space-x-reverse))) !important;
}
