.hospital-slider-image {
    padding: 10px 13px 13px 13px;
    border-radius: 5px;

    transition: 1s all;

    &:hover {
        background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
        color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.hospital-slider-image-inner {
    padding: 10px 13px 13px 13px;
    border-radius: 5px;

    transition: 1s all;
    background: linear-gradient(323.79deg, #125a77 35.68%, #397d99 92.17%);
    color: white;
}

// .hospital-slider-image-inner-show{
//     width: 50px;
//     height: 50px;
// }

.hospital-slider-image-inner-show-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }

  .hospital-slider-image-inner-show{
    border-radius: 5px !important;
    img{
        border-radius: 5px !important;
    }
  }
  
  .hospital-slider-image-inner-show {
    // flex: 0 0 calc(50% - 20px);
    // flex-grow: 12
    height: 9rem;
    width: 12rem;
    @media (min-width: 0px) and (max-width: 1200px){
        height: 6rem;
        width: 6rem;
    }
    margin: 10px;
    // top: 0;
    img{
        object-fit: cover;
    }

  }

  .delete-button-image{
    right: 0;
    border-radius: 50px;
    background-color: rgb(255, 0, 0);
    color: white;
    border: 1px solid white;
    transform: translate(12px, -14px);
  }

  .hospital-upload-image{
    background-color: transparent;
    border: none;

  }

  .doc-setting-input-hospital
   .ant-modal .ant-modal-content{
        padding: 20px 0px !important;
  }

  .img-modal-header{
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .img-modal-header-text1{
    color: #202020;

font-family: 'Poppins Medium';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }