
.upload-certificate-modal{

    .ant-modal-content{
        // background-color: aqua !important;
        padding: 0;
        border-radius: 8px;
    }

    .drag-drop-modal-content{

        .dnd-modal-header{
            border-bottom: 1px solid #E4E3E4;
            padding-left: 40px;
            padding-right: 25px;
            padding-top: 20px;
            padding-bottom: 20px;

            .modal-heading{
                font-size: 16px;
                font-weight: 500;
                color: #202020;
                margin: 0;
            }
        }

        .dnd-modal-body{

            padding-left: 40px;
            padding-right: 25px;
            padding-top: 35px;
            padding-bottom: 20px;

            .browse-text{
                font-size: 16px;
                font-weight: 600;
                color: #094963;
            }

            .document-text{
                font-size: 14px;
                font-weight: 500;
                color: #535353;
                margin: 0;
                margin-left: 25px;
            }

        }

        .dnd-modal-footer{

            text-align: end;
            margin-top: 40px;

            button{
                font-size: 12px;
                font-weight: 500;
                width: 18%;
                outline: none;
                border: none;
                height: 40px;
                border-radius: 5px;
                margin-left: 13px;
            }
        }

        
    }
}

