
.connecting{
    font-size: 16px;
    font-weight: 400;
    color: white;
}

.reaconnectingTextDiv{

    position: absolute;
    top: 12%;
    text-align: center;

    .reconnectingUserName{
        font-size: 24px;
        font-weight: 600;
        color: white;
    }
}

.reconnectingProfile{
    height: 200px;
    width: 200px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.animationdiv{
    height: 200px;
    width: 200px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: call 2.5s ease infinite;
}

@keyframes call {
    0% {
        box-shadow: 0 0 0 -10px rgba(255, 255, 255, 0.5),
                    0 0 0 1px rgba(255, 255, 255, 0.3);
    }
    50% {
        box-shadow: 0 0 0 22px rgba(255, 255, 255, 0.5),
                    0 0 0 60px rgba(255, 255, 255, 0.3);
    }
    100% {
        box-shadow: 0 0 0 -10px rgba(255, 255, 255, 0.5),
                    0 0 0 1px rgba(255, 255, 255, 0.3);
    }
} 

.ContralBarDiv{

    background: linear-gradient(323.79deg, #125A77 43.38%, #2D738F 92.17%);
    gap: 50px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .ControlButtonsDiv{
        padding: 15px;
        border-radius: 20px;
        background-color: rgba(255,255,255, 0.1);
        cursor: pointer;
    }
    
    .disconnectButton{
        background-color: #EB5757;
        padding: 15px 30px;
        border-radius: 20px;
        cursor: pointer;
    }

    .homeScreenButton{
        border: 1px solid white;
        border-radius: 20px;
        padding: 15px 40px;
        font-size: 18px;
        font-weight: 500;
        color: white;
    }

}

.patientScreenText{
    h6{
        font-size: 24px;
        font-weight: 600;
        font-family: Encode Sans;
    }

    p{
        font-size: 14px;
        font-weight: 500;
        font-family: Encode Sans;
    }
}

.VideoScreenBorder{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}